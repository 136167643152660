import Vue from 'vue'

/* Vue Axios */
import VueAxios from 'vue-axios'
import axios from 'axios'
import AxiosPlugin from 'vue-axios-cors'
Vue.use(VueAxios, axios, AxiosPlugin)
/* End Vue Axios */

/* IziToast */
import 'izitoast/dist/css/iziToast.min.css';
import iziToast from 'izitoast';
Vue.use(iziToast);
/* End IziToast */

/* Vue Masonry */
import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry)
/* End Vue Masonry */

/* Meta Tags */
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
/* End Meta Tags */

/* Vue Paginate */
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)
/* End Vue Paginate */

/* Lazyload Image */
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);
/* End LazyLoad Image */


/* Vue  Tel input */
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
Vue.use(VueTelInput)
/* End Vue Tel Input */

import VueFbCustomerChat from 'vue-fb-customer-chat'
Vue.use(VueFbCustomerChat, {
    page_id: '108710147573742', //  change 'null' to your Facebook Page ID,
    theme_color: '#333333', // theme color in HEX
    locale: 'en_US', // default 'en_US'
})

export default []