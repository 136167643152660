import Vue from 'vue'
import Router from 'vue-router'
//import store from '@/store'

// mixen routers
import HomeRoutes from '@/router/modules/home'
import PropertyTypeRoutes from '@/router/modules/propertyType'
import PropertyRoutes from '@/router/modules/property'
import SearchRoutes from '@/router/modules/search'
import NewsRoutes from '@/router/modules/news'
import TestimonialRoutes from '@/router/modules/testimonial'
import ContactRoutes from '@/router/modules/contact'
import ErrorRoutes from '@/router/modules/error'


var allRoutes = [];
allRoutes = allRoutes.concat(
      HomeRoutes, 
      PropertyTypeRoutes,
      PropertyRoutes,
      SearchRoutes,
      NewsRoutes,
      TestimonialRoutes,
      ContactRoutes,
      ErrorRoutes 
   )

Vue.use(Router)
export default new Router({
  mode: 'history',
  base: '/',
  fallback: true,
  scrollBehavior() {
    window.scrollTo(0,0);
  },
  routes: allRoutes
})
