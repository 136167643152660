export default [
    
    { 
        path: '/', 
        name: 'home', 
        component: () => import('@/views/home/List.vue')
    },
    { 
        path: '/Thankyou/', 
        name: 'show-thank-you', 
        component: () => import('@/views/layouts/Thankyou.vue')
    },
    { 
        path: '/landing/Thankyou/', 
        name: 'show-thank-you', 
        component: () => import('@/views/layouts/Thankyou.vue')
    }
]
