export default [

    { 
        path: '/news', 
        name: 'news', 
        component: () => import('@/views/news/List.vue')
    },
    { 
        path: '/news/:slug', 
        name: 'show-news', 
        component: () => import('@/views/news/Show.vue')
    }

]
