<template>
  <div id="app">
    <Header :isHome="(this.$route.path == '/') ? true : false"></Header>

      <router-view/>

    <Footer :isHome="(this.$route.path == '/') ? true : false"></Footer>
  </div>
</template>

<script>
  import Header from '@/views/layouts/Header.vue'
  import Footer from '@/views/layouts/Footer.vue'

  export default {
      name: 'App',
      components: {
        Header,
        Footer
      },
      data(){
        return {
          //
        }
      },
      watch: {},
      mounted() {},
      created() {
        //
      },
      methods: {
        //
      },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
