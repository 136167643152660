<template>
    <div class="">

        <header :class="(isHome) ? 'layout_boxed' : 'layout_default'">

            <div :class="(isHome) ? 'topbar' : 'topbar grey'">
              <div class="container">
                <div class="row">
                  <div class="col-md-6">
                    <p v-if="pgLoading" class="webkit-animation! webkit-200-30"></p>
                    <p v-if="!pgLoading" 
                       :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                    </p>
                  </div>
                  <div class="col-md-6 text-right">
                    <ul class="breadcrumb_top text-right">
                      <li>
                        <a href="javascript:;" 
                            @click="changeLangUrl(($i18n.locale == 'ar') ? 'en' : 'ar')">
                            <i class="icon-world"></i> {{ $i18n.locale == 'ar' ? 'English' : 'Arabic' }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div :class="(isHome) ? 'header-upper dark' : 'header-upper '">
              <div class="container">
                <div class="row">
                    
                  <div v-if="!landing" class="col-md-3 col-sm-12">
                    <div class="logo">
                        <router-link v-if="$i18n.locale == 'en'" to="/">
                            <img
                                    v-lazy="(isHome)
                            ?(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''
                            :(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''"

                                    :src="(isHome)
                            ?(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''
                            :(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''"

                                    :alt="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.alt_ar : logo_white.image.alt_en : '' : ''"
                                    :title="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.title_ar : logo_white.image.title_en : '' : ''"

                                    style="width: 190px; height:75px; margin-top:-10px">
                        </router-link>
                        <router-link v-if="$i18n.locale == 'ar' " to="/?lang=ar">
                            <img
                            v-lazy="(isHome)
                            ?(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''
                            :(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''"

                            :src="(isHome) 
                            ?(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''
                            :(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''"

                            :alt="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.alt_ar : logo_white.image.alt_en : '' : ''" 
                            :title="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.title_ar : logo_white.image.title_en : '' : ''"     
                            
                            style="width: 190px; height:75px; margin-top:-10px">
                      </router-link>
                    </div>
                  </div>

                  <div v-if="landing" class="col-md-3 col-sm-12">
                    <div class="logo" style="display: block;padding: 15px 0;">
                        <img 
                            v-lazy="(isHome)
                            ?(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''
                            :(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''"

                            :src="(isHome) 
                            ?(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''
                            :(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''"

                            :alt="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.alt_ar : logo_white.image.alt_en : '' : ''" 
                            :title="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.title_ar : logo_white.image.title_en : '' : ''"     
                            
                            style="width: 190px; height:75px; margin-top:-10px">
                    </div>
                  </div>

                  <div class="col-md-9 col-sm-12 right hidden-xs">
                    <div v-if="phone_number" class="info-box first">
                      <div class="icons"><i class="icon-telephone114"></i></div>
                      <ul>
                        <li>
                          <strong :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ ($i18n.locale == 'ar') ? phone_number.title_ar : phone_number.title_en  }}
                          </strong>
                        </li>
                        <li v-if="pgLoading">
                          <p class="webkit-animation webkit-150-25"></p>
                        </li>
                        <li v-if="!pgLoading">
                          <a :href="'tel:'+phone_number.strip_body">{{ phone_number.strip_body }}</a>
                        </li>
                      </ul>
                    </div>
                    <div v-if="email_address" class="info-box">
                      <div class="icons"><i class="icon-icons142"></i></div>
                      <ul>
                        <li>
                          <strong :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ ($i18n.locale == 'ar') ? email_address.title_ar : email_address.title_en  }}
                          </strong>
                        </li>
                        <li v-if="pgLoading">
                          <p class="webkit-animation webkit-150-25"></p>
                        </li>
                        <li v-if="!pgLoading">
                          <a :href="'mailto:'+email_address.strip_body"
                              v-html="email_address.strip_body">
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <nav v-if="!landing" class="navbar navbar-default navbar-sticky bootsnav">
                <div class="container">

                    <div v-if="isHome" class="attr-nav">
                    <div v-if="pgLoading" class="white-touch webkit-animation webkit-115-60"></div>
                        <router-link v-if="$i18n.locale == 'en'"
                                     to="/contacts"
                                     class="btn-touch uppercase white-touch"
                                     :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ $t('header.get_in_touch') }}
                        </router-link>
                        <router-link v-if="$i18n.locale == 'ar'"
                                     to="/contacts?lang=ar"
                                     class="btn-touch uppercase white-touch"
                                     :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ $t('header.get_in_touch') }}
                        </router-link>
                    </div>

                    <div class="attr-nav hidden-xs">
                    <ul v-if="pgLoading" class="social_share">
                        <li v-for="(row, index) in 3" :key="index">
                        <a :class="row.provider"></a>
                        </li>
                    </ul>
                    <ul v-if="!pgLoading" class="social_share">
                        <li v-for="(social, index) in socials" :key="index">
                        <a  :href="social.provider_url" 
                            target="_blank" 
                            :class="social.provider">
                            <i :class="'fa fa-'+social.provider"></i>
                        </a>
                        </li>
                    </ul>
                    </div>


                    <div class="navbar-header">
                        <button type="button" 
                            @click="toggleTab"
                            class="navbar-toggle" 
                            data-toggle="collapse" 
                            data-target="#navbar-menu">
                            <i :class="(isToggled) ? 'fa fa-times' : 'fa fa-bars'"></i>
                        </button>

                        <router-link v-if="$i18n.locale == 'en'" to="/" class="navbar-brand sticky_logo">
                            <img 
                                v-lazy="'/assets/images/logo-white.webp'" 
                                src="/assets/images/logo-white.webp" 
                                class="logo" 
                                alt="logo"
                                title="logo">
                        </router-link>
                        <router-link v-if="$i18n.locale == 'ar'" to="/?lang=ar" class="navbar-brand sticky_logo">
                            <img
                                    v-lazy="'/assets/images/logo-white.webp'"
                                    src="/assets/images/logo-white.webp"
                                    class="logo"
                                    alt="logo"
                                    title="logo">
                        </router-link>
                    </div>


                    <div class="collapse navbar-collapse" id="navbar-menu">
                        <ul class="nav navbar-nav" data-in="fadeIn" data-out="fadeOut">

                            <li 
                                :class="(hasPath('/'+nav.slug, idx)) ? 'active' : ''"
                                @click="toggleTab"
                                v-for="(nav, idx) in navigations"
                                :key="idx">
                                <router-link v-if="$i18n.locale == 'en'"
                                        :to="(idx == 0) ? nav.slug : '/'+nav.slug"
                                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                    {{ ($i18n.locale == 'ar') ? nav.title_ar : nav.title_en }}
                                </router-link>
                                <router-link v-if="$i18n.locale == 'ar'"
                                        :to="(idx == 0) ? nav.slug+'?lang=ar' : '/'+nav.slug+'?lang=ar'"
                                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                    {{ ($i18n.locale == 'ar') ? nav.title_ar : nav.title_en }}
                                </router-link>
                            </li>

                        </ul>
                    </div>


                </div>
            </nav>
        </header>

    </div>
</template>


<script>
export default {
    name: 'Header',
    props:['isHome'],
    components: {},
    data(){
        return {
            pgLoading: true,
            isToggled: false,
            landing: false,
            logo_white: '',
            logo_dark: '',
            phone_number: '',
            email_address: '',
            address: '',

            socials: [],
            navigations: [],

        }
    },
    mounted() { },
    created() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);

        if(params.get("lang")){
            this.changeLang(params.get("lang"));
        }

        if(localStorage.getItem('locale')) {
                this.$i18n.locale = localStorage.getItem('locale');
                this.changeLang(this.$i18n.locale);
        }

        this.fetchData();
        if (window.location.href.includes("landing")) {
            this.landing = true;
        }
    },
    methods: {
        //
        fetchData() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/app/header',
                method: 'GET',
                data: {},
                params: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.logo_white = res.data.logo_white;
                this.logo_dark  = res.data.logo_dark;
                this.phone_number = res.data.phone_number;
                this.email_address = res.data.email_address;
                this.address = res.data.address;

                this.socials = res.data.socials;
                this.navigations = res.data.navigations;
                var swipers = document.getElementsByClassName('swiper-container');
                for (var i =0; i< swipers.length; i++) {
                  if (localStorage.getItem('locale') == 'en') {
                      swipers[i].style.direction = "ltr";
                  } else {
                      swipers[i].style.direction = "ltr";
                  }
                }
            })
            .catch(() => {})
            .finally(() => {});
        },

        
        toggleTab() {
            let app = document.querySelectorAll("div.navbar-collapse");
            let appx = app[app.length-1];

            if(this.isToggled) {
                appx.classList.remove('in');
                this.isToggled = false;
            } else {
                appx.classList.add('in');
                this.isToggled = true;
            }
        },


        changeLang(lang) {
            if(lang == 'ar') {
                this.$i18n.locale = 'ar';
                this.$i18n.local  = 'ar';
                localStorage.setItem('locale', 'ar');

                // css.01
                let para1 = document.querySelectorAll("head link.css-rtl-1");
                let parax1 = para1[para1.length-1];
                parax1.href = "/assets/css/bootstrap.rtl.min.css";

                // css.02
                let para2 = document.querySelectorAll("head link.css-rtl-2");
                let parax2 = para2[para2.length-1];
                parax2.href = "/assets/css/rtl.css";
            } else {
                this.$i18n.locale = 'en';
                this.$i18n.local  = 'en';
                localStorage.setItem('locale', 'en');

                // css.01
                let para1 = document.querySelectorAll("head link.css-rtl-1");
                let parax1 = para1[para1.length-1];
                parax1.href = "/assets/css/empty.css";

                // css.02
                let para2 = document.querySelectorAll("head link.css-rtl-2");
                let parax2 = para2[para2.length-1];
                parax2.href = "/assets/css/empty.css";

            }
        },
        changeLangUrl(lang) {
            if(lang == 'ar') {
                this.$i18n.locale = 'ar';
                this.$i18n.local  = 'ar';
                localStorage.setItem('locale', 'ar');

                // css.01
                let para1 = document.querySelectorAll("head link.css-rtl-1");
                let parax1 = para1[para1.length-1];
                parax1.href = "/assets/css/bootstrap.rtl.min.css";

                // css.02
                let para2 = document.querySelectorAll("head link.css-rtl-2");
                let parax2 = para2[para2.length-1];
                parax2.href = "/assets/css/rtl.css";
            } else {
                this.$i18n.locale = 'en';
                this.$i18n.local  = 'en';
                localStorage.setItem('locale', 'en');

                // css.01
                let para1 = document.querySelectorAll("head link.css-rtl-1");
                let parax1 = para1[para1.length-1];
                parax1.href = "/assets/css/empty.css";

                // css.02
                let para2 = document.querySelectorAll("head link.css-rtl-2");
                let parax2 = para2[para2.length-1];
                parax2.href = "/assets/css/empty.css";

            }
            this.$router.push({ path: this.$route.path , query: { lang: lang } })
        },

        hasPath(slug, idx) {
            if(idx == 0 && this.$route.path == '/') {
                return true;
            } else if(this.$route.path.includes(slug)) {
                return true;
            } else {
                return false;
            }
        },


    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
