export default [

    { 
        path: '/properties', 
        name: 'properties', 
        component: () => import('@/views/properties/List.vue')
    },
    { 
        path: '/properties/:slug', 
        name: 'show-properties', 
        component: () => import('@/views/properties/Show.vue')
    },
    { 
        path: '/properties/landing/:slug', 
        name: 'show-landing-properties', 
        component: () => import('@/views/properties/landing.vue')
    },

]
