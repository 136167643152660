import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

export const i18n = new VueI18n ({
    local: 'en',
    locale: 'en',
    fallbackLocale: 'en',
    silentTranslationWarn: true,
    messages: {
        
        en: {
            //

            header: {
                home: 'Home',
                properties: 'Properties',
                news: 'News',
                testimonials: 'Testimonials',
                contact_us: 'Contact Us',
                get_in_touch: 'Get In Touch',
                phone_number: 'Phone Number',
                email_address: 'Email Address',
            },
 
            app: {
                search: 'Search',
                loadMore: 'Load More',

                propertyType: 'Property Type',
                properties: 'Properties',

                view_all: 'View All',
                contact_us: 'Contact Us',
                get_in_touch: 'Get In Touch',
                send_us_a_message: 'Send Us A Message',
                name: 'Name',
                jobTitle: 'job Title',
                phone_number: 'Phone Number',
                email_address: 'Email Address',
                message: 'Message',
                submit_now: 'Submit Now',
                featured_properties: 'Featured Properties',
                best_deal_properties: 'Best Deal Properties',

                property_description: 'Property Description',
                quick_summary: 'Facilities',
                features: 'Features',
                video_presentation: 'Video Presentation',
                property_map: ' Map',
                similar_properties: 'You might be interested in',

                categories: 'Categories',
                featured_news: 'Featured News',
                similar_news: 'You might be interested in',
                share: 'Share',
                more_details: 'More Details',

                opps: 'Opps!!',
                looks_something_wrong: 'Looks like something went wrong.',
                page_not_exists: 'The page you are looking for was moved, removed, renamed or might never existed.',
                go_back_to_home: 'go back to home page',
            },

            footer: {
                search_by_area: 'Search by Area',
                latest_news: 'Latest News',
                get_in_touch: 'Get In Touch',
            },
        },

        ar: {
            //
            
            header: {
                home: 'الرئيسية',
                properties: 'العقارات',
                news: 'الأخبار',
                testimonials: 'أراء العملاء',
                contact_us: 'تواصل معنا',
                get_in_touch: 'أتصل بنا',
                phone_number: 'رقم الهاتف',
                email_address: 'البريد الإلكتروني',
            },
        
            
            app: {
                search: 'بحث',
                loadMore: 'المزيد',
                
                propertyType: 'أنواع العقارات',
                properties: 'عقارات',

                view_all: 'مشاهدة الكل',
                contact_us: 'تواصل معنا',
                get_in_touch: 'إتصل بنا',
                send_us_a_message: 'إرسل لنا رسالة',
                name: 'الأسم',
                jobTitle: 'المسمى الوظيفي',
                phone_number: 'رقم الهاتف',
                email_address: 'البريد الإلكتروني',
                message: 'الرسالة',
                submit_now: 'إرسل الآن',
                featured_properties: 'العقارات الحديثة',
                best_deal_properties: 'أفضل الصفقات',

                property_description: 'الوصف',
                quick_summary: 'مميزات المشروع',
                features: 'المميزات',
                video_presentation: 'الفيديو',
                property_map: 'الخريطة',
                similar_properties: 'قد تكون مهتم ايضاً في',

                categories: 'الأقسام',
                featured_news: 'أخر الأخبار',
                similar_news: 'قد تكون مهتم ايضاُ في',
                share: 'مشاركة',
                more_details: 'التفاصيل',

                opps: 'خطأ!!',
                looks_something_wrong: 'حدث خطأ في الخادم',
                page_not_exists: 'مش عارف بصراحة من اية بس خير ان شاء الله',
                go_back_to_home: 'أرجع للصفحة الرئيسية',
            },

            footer: {
                search_by_area: 'عمليات البحث',
                latest_news: 'أحدث الأخبار',
                get_in_touch: 'تواصل معنا',
            },
        },

        // feel free to add multiple languages as much as you want
    }
})