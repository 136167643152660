<template>
    <div class="">

    <div v-if="!landing" class="fixed_footer">
      <router-link v-if="$i18n.locale == 'en'"
                   to="/contacts"
                   class="contact_footer"
                   :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
        {{ $t('header.get_in_touch') }}
      </router-link>
      <router-link v-if="$i18n.locale == 'ar'"
                   to="/contacts?lang=ar"
              class="contact_footer"
              :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
        {{ $t('header.get_in_touch') }}
      </router-link>

      <!-- Whats App Sticky -->
      <div v-if="whatsapp" class="whatsAppIcon">
        <a :href="(whatsapp) ? whatsapp.provider_url : ''"
           target="_blank">
          <img src="/assets/whatsApp.png"
               style="width: 40px">
        </a>
      </div>
      <!-- ENd Whats App Sticky -->

      <div class="whatsAppIcon">
        <a href="https://m.me/capitaladvisors.eg" class="messenger" aria-label="send message to messenger" rel="noopener noreferrer" target="_blank">
          <img src="/assets/facebook_messenger.png"  style="width: 40px">
        </a>
      </div>

    </div>
        <!--Footer-->
        <footer :class="(isHome) ? 'padding_top' : 'padding_top footer2'">
          <div class="container">
            <div class="row">


              <div class="col-md-3 col-sm-6">
                <div class="footer_panel bottom30">

                  <!-- Logo -->
                  <div v-if="!landing">
                    <div v-if="pgLoading" class="webkit-animation webkit-145-50 mb-5"></div>
                    <router-link v-if="$i18n.locale == 'en'"  to="/" class="logo bottom30">
                      <img 
                          v-lazy="(isHome)
                          ?(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''
                          :(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''"

                          :src="(isHome) 
                          ?(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''
                          :(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''"

                        :alt="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.alt_ar : logo_white.image.alt_en : '' : ''" 
                          :title="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.title_ar : logo_white.image.title_en : '' : ''"     

                          style="width: 200px;height:80px">
                    </router-link>
                    <router-link v-if="$i18n.locale == 'ar'"  to="/?lang=ar" class="logo bottom30">
                      <img
                              v-lazy="(isHome)
                          ?(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''
                          :(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''"

                              :src="(isHome)
                          ?(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''
                          :(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''"

                              :alt="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.alt_ar : logo_white.image.alt_en : '' : ''"
                              :title="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.title_ar : logo_white.image.title_en : '' : ''"

                              style="width: 200px;height:80px">
                    </router-link>
                  </div>

                  <div style="margin-bottom: 30px;" v-if="landing">
                    <div  v-if="pgLoading" class="webkit-animation webkit-145-50 mb-5"></div>
                      <img 
                          v-lazy="(isHome)
                          ?(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''
                          :(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''"

                          :src="(isHome) 
                          ?(logo_dark) ?(logo_dark.image) ? logo_dark.image.url : '' : ''
                          :(logo_white) ?(logo_white.image) ? logo_white.image.url : '' : ''"

                        :alt="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.alt_ar : logo_white.image.alt_en : '' : ''" 
                          :title="(logo_white) ?(logo_white.image) ?($i18n.locale == 'ar') ? logo_white.image.title_ar : logo_white.image.title_en : '' : ''"     

                          style="width: 200px;height:80px">
                  </div>
                  <!-- End Logo -->

                  <!-- Bio -->
                  <div v-if="pgLoading" class="bottom15 webkit-animation webkit-100per-150 mb-5"></div>
                  <p v-if="!pgLoading" 
                      class="bottom15" 
                      :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                      v-html="(footer_about) ?($i18n.locale == 'ar') ? footer_about.body_ar : footer_about.body_en : ''">
                  </p>
                  <!-- End Bio -->

                  <!-- Socials -->
                  <div v-if="!landing">
                    <ul v-if="pgLoading" class="social_share">
                      <li v-for="(social, idx) in 5" :key="idx">
                        <a href="javascript:;" class="webkit-animation webkit-30-30"></a>
                      </li>
                    </ul>
                    <ul v-if="!pgLoading" class="social_share">
                      <li v-for="(social, idx) in socials" :key="idx">
                        <a  :href="social.provider_url"
                            target="_blank" 
                            :class="social.provider">
                          <i :class="'fa fa-'+social.provider"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <!-- End Social -->

                </div>
              </div>

              <div v-if="!landing" class="col-md-3 col-sm-6">
                <div class="footer_panel bottom30">
                  <div v-if="pgLoading" class="webkit-animation webkit-145-50 mb-5"></div>
                  <h4 v-if="!pgLoading" 
                      class="bottom30"
                      :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                      {{ $t('footer.search_by_area') }}
                  </h4>

                  <ul v-if="pgLoading" class="area_search">
                    <li v-for="(i, idx) in 5" :key="idx">
                      <a href="javascript:;" class="webkit-animation webkit-100per-30 mbg5"></a>
                    </li>
                  </ul>
                  <ul v-if="!pgLoading" class="area_search">
                    <li v-for="(location, idx) in locations" :key="idx">
                      <router-link v-if="$i18n.locale == 'en'"
                                   :to="'/search?location='+location.slug"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                          <i class="icon-icons74"></i>
                          {{ ($i18n.locale == 'ar') ? location.title_ar : location.title_en }} ( {{ location.count_no }} )
                      </router-link>
                      <router-link v-if="$i18n.locale == 'ar'"
                                   :to="'/search?location='+location.slug+'&lang=ar'"
                              :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        <i class="icon-icons74"></i>
                        {{ ($i18n.locale == 'ar') ? location.title_ar : location.title_en }} ( {{ location.count_no }} )
                      </router-link>
                    </li>
                  </ul>

                </div>
              </div>

              <div class="col-md-3 col-sm-6">
                <!-- <div class="footer_panel bottom30">
                  <div v-if="pgLoading" class="webkit-animation webkit-145-50 mb-5"></div>
                  <h4 v-if="!pgLoading" 
                      class="bottom30"
                      :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                        {{ $t('footer.latest_news') }}
                  </h4>

                  <div v-if="pgLoading">
                    <div v-for="(news, idx) in 3" :key="idx" class="media">
                      <div class="media-object">
                        <div class="webkit-animation webkit-50-50 mb-5"></div>
                      </div>
                      <div class="media-body">
                        <div class="webkit-animation webkit-100per-50"></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="!pgLoading">
                    <div v-for="(news, idx) in latestNews" :key="idx" class="media">
                      <router-link 
                          :to="'/news/'+news.slug" 
                          class="media-object">
                        <img v-lazy="(news.image) ? news.image.url: ''" 
                            :src="(news.image) ? news.image.url: ''" 
                            :alt="($i18n.locale == 'ar') ? news.title_ar : news.title_en"
                            style="border-radius: 5px">
                      </router-link>
                      <div class="media-body">
                        <router-link 
                          :to="'/news/'+news.slug"
                          :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ ($i18n.locale == 'ar') ? news.title_ar : news.title_en }}
                        </router-link>
                        <span><i :class="(isHome) ? 'icon-clock4' : 'icon-clock2'"></i>{{ news.created_at }}</span>
                      </div>
                    </div>
                  </div>

                </div> -->
              </div>



              <div class="col-md-3 col-sm-6">
                <div class="footer_panel bottom30">
                  <div v-if="pgLoading" class="webkit-animation webkit-145-50 mb-5"></div>
                  <h4 v-if="!pgLoading" 
                      class="bottom30"
                      :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                      {{ $t('footer.get_in_touch') }}
                  </h4>

                  <ul v-if="pgLoading" class="">
                    <li v-for="(con, idx) in 5" :key="idx" 
                        class="webkit-animation webkit-100per-30 mb-5">
                    </li>
                  </ul>
                  <ul v-if="!pgLoading" class="getin_touch">

                    <li v-if="phone_number">
                      <a :href="'tel:'+phone_number.strip_body">
                        <i class="icon-telephone114"></i>
                        {{ phone_number.strip_body }}
                      </a>
                    </li>

                    <li v-if="email_address">
                      <a :href="'mailto:'+email_address.strip_body">
                        <i class="icon-icons142"></i>
                        {{ email_address.strip_body }}</a>
                    </li>

                    <li v-if="address" 
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                        style="display: flex">
                      <i class="icon-icons74"></i>
                      <span v-html="($i18n.locale == 'ar') ? address.strip_body_ar : address.strip_body"></span>
                    </li>

                  </ul>

                </div>
              </div>

            </div>
          </div>
        </footer>
        <!-- End Footer -->


   
        <!--Copyright-->
        <div :class="(isHome) ? 'copyright' : 'copyright index2'">
          <div class="copyright_inner">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <p v-if="pgLoading" class="webkit-animation webkit-50per-30"></p>
                  <p v-if="!pgLoading" 
                    v-html="(copyright) ?($i18n.locale == 'ar') ? copyright.title_ar : copyright.title_en : ''"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                  </p>
                </div>
                <div class="col-md-6 text-right"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Copyright -->
        
    </div>
</template>


<script>
    export default {
        name: 'Footer',
        props:['isHome'],
        components: {},
        data(){
            return {
              pgLoading: true,
              landing: false,
              socials: [],
              locations: [],
              latestNews: [],

              logo_white: '',
              logo_dark: '',
              footer_about: '',
              phone_number: '',
              email_address: '',
              address: '',
              copyright: '',
              whatsapp: '',
            }
        },
        mounted() {},
        created() {
          //
          if(localStorage.getItem('locale')) {
            this.locale = localStorage.getItem('locale');
          } 

          this.fetchData();

         if (window.location.href.includes("landing")) {
            this.landing = true;
         }
        },
        methods: {
          //
          fetchData() {
              this.pgLoading = true;
              this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
              };
              const options = {
                  url: window.baseURL+'/app/footer',
                  method: 'GET',
                  data: {},
                  params: {}
              }
              this.axios(options)
              .then(res => {
                this.pgLoading = false;
                this.logo_white = res.data.logo_white;
                this.logo_dark  = res.data.logo_dark;
                this.footer_about = res.data.footer_about;
                this.phone_number = res.data.phone_number;
                this.email_address = res.data.email_address;
                this.address = res.data.address;
                this.copyright = res.data.copyright;
                this.whatsapp = res.data.whatsapp;

                this.socials = res.data.socials;
                this.locations = res.data.locations;
                this.latestNews = res.data.latestNews;
              })
              .catch(() => {})
              .finally(() => {});
          },

        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .fixed_footer{
    height: 40px;
    position: fixed;
    z-index: 99;
    right: 5px;
    bottom: 30px;
    display: flex;
    direction: rtl;
  }
  .whatsAppIcon {
    width: 40px;
    height:40px;
    margin-right: 20px;
  }
  .contact_footer{
    background-color: #ffd119;
    border-radius: 20px;
    padding: 0 20px;
    line-height: 40px;
    -webkit-box-shadow: 0 2px 9px rgb(0 0 0 / 24%);
    box-shadow: 0 2px 9px rgb(0 0 0 / 24%);
  }
</style>
